import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Drawer, DrawerProps } from "react-daisyui";

import IconClose from "../Icons/IconClose";

import useLockBodyScroll from "~/hooks/useLockBodyScroll";
import { cn } from "~/utilities/cn";

export interface ICustomDrawerProps
  extends Omit<DrawerProps, "open" | "onClickOverlay" | "title"> {
  actions?: React.ReactNode;
  title?: string | React.ReactNode;
  slideClassName?: string;
  showAction?: boolean;
  showCloseIcon?: boolean;
  sideClassNameContainer?: string;
  shadowClass?: string;
  wrapperTitleClass?: string;
}

export type CustomDrawerRef = { onClose: () => void };

const CustomDrawer = forwardRef<CustomDrawerRef, ICustomDrawerProps>(
  (
    {
      side,
      actions,
      title,
      slideClassName,
      showAction = true,
      showCloseIcon = true,
      sideClassNameContainer = "",
      shadowClass = "",
      wrapperTitleClass = "",
      children,
      ...restProps
    },
    ref
  ) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isBrowser, setIsBrowser] = useState<boolean>(false);

    // Lock body scroll when modal is open
    useLockBodyScroll(isOpen);

    useImperativeHandle(ref, () => ({
      onClose: () => setOpen(false),
    }));

    useEffect(() => {
      setIsBrowser(true);
    }, []);

    useEffect(() => {
      if (isBrowser) {
        const bodyTag = document.body;
        bodyTag.classList.toggle("overflow-hidden", isOpen);

        return () => {
          bodyTag.classList.remove("overflow-hidden");
        };
      }
    }, [isOpen, isBrowser]);

    const handleOverlayClick = () => setOpen(false);
    const handleToggleClick = () => setOpen(!isOpen);
    const handleCloseIconClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setOpen(false);
    };

    return (
      <div data-name="CustomDrawer" className="hidden w-full lg:block">
        <Drawer
          {...restProps}
          key={`CustomDrawer-${Math.random()}`}
          open={isOpen}
          onClickOverlay={handleOverlayClick}
          sideClassName={cn(
            "hidden lg:flex fixed w-full z-100 overflow-auto",
            sideClassNameContainer,
            isOpen ? shadowClass : ""
          )}
          side={
            <div
              className={cn(
                "hidden h-full w-full flex-col justify-between gap-3 bg-headerBackground px-6 py-[18px] lg:flex",
                slideClassName
              )}
            >
              {showCloseIcon && (
                <div
                  className={cn(
                    "drawer-header flex justify-end",
                    wrapperTitleClass
                  )}
                >
                  {title}
                  <IconClose onClick={handleCloseIconClick} />
                </div>
              )}
              {title && <div className="divider" />}
              <div
                data-name="CustomDrawer-side"
                className="flex-1 overflow-auto"
              >
                {side}
              </div>
              {showAction && (
                <div data-name="CustomDrawer-actions">
                  <div className="divider" />
                  {actions}
                </div>
              )}
            </div>
          }
        >
          <div className="w-full" onClick={handleToggleClick}>
            {children}
          </div>
        </Drawer>
      </div>
    );
  }
);

CustomDrawer.displayName = "CustomDrawer";

export default CustomDrawer;
